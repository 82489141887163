<template>
  <div>
    <b-button
      size="sm"
      class="button-export-style ml-2 pl-2 pr-2"
      title="Génerer des factures PROFORMA Masters"
      v-b-modal.generateProformaMaster
      @click="handleOpenModal"
    >
      <font-awesome-icon icon="retweet" />
    </b-button>
    <b-modal
      ref="generateProformaMaster"
      id="generateProformaMaster"
      :hide-footer="true"
      :hide-header="true"
      modal-class="cutsom-modal-bootstrap"
      @hidden="resetModal('generateProformaMaster')"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Génerer des factures PROFORMA Masters</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('generateProformaMaster')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form class="form-modal-custom-style">
            <div class="mt-4 ml-3">
              <p class="paragraph-style">
                Voulez vous générer des factures proforma Pour les Masters?
              </p>
            </div>
            <div>
              <div>
                <b-form-group
                  label="Date de dépôt"
                  label-for="date-fin-master"
                  class="input-modal-champ mb-0"
                >
                  <date-picker
                    value-type="format"
                    type="date"
                    v-model="date_depot"
                    :clear-button="false"
                    @change="handleChangeDateDepot"
                  ></date-picker
                ></b-form-group>
              </div>
              <div class="pl-2 mt-4">
                <b-form-group
                  label="Liste Des Dossiers"
                  label-for="master-ids"
                  class="input-modal-champ mb-0  input-modal-champ-custom-master"
                >
                  <div
                    class="d-flex justify-content-between mb-2"
                    v-if="getListProjectForSpecificDate.length"
                  >
                    <b-form-checkbox
                      v-model="checkAllDossier"
                      @change="changeAllCheckDossiers"
                      >Tous</b-form-checkbox
                    >
                    <b-form-group
                      label=""
                      label-for="search-add"
                      class="input-modal-champ mb-0"
                    >
                      <b-form-input
                        autocomplete="off"
                        v-model="searchValue"
                        class="b-form-select-raduis"
                        placeholder="Num dossier"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </b-form-group>
                <div
                  class="chargement chargement-loading-icon mt-1 ml-3 "
                  v-if="loaderProject"
                >
                  <div class="spinner-border sp-co-color" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>

                <div
                  v-if="
                    getListProjectForSpecificDate.length == 0 && !loaderProject
                  "
                  class="error-msg mb-2"
                >
                  Aucun dossier
                </div>
                <!-- <br /> -->
                <b-form-group
                  v-if="getListProjectForSpecificDate.length"
                  label=""
                  v-slot="{ ariaDescribedby }"
                  class="d-block"
                >
                  <div class="custom-class-fields-av scroll-specifics-params">
                    <b-form-checkbox
                      v-for="(option, index) in computedListProduct"
                      :key="index"
                      v-model="projects_ids"
                      :aria-describedby="ariaDescribedby"
                      :value="option.id"
                      @change="changeOptionDossier"
                    >
                      {{ option.numero_dossier }}
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </div>
              <div class="pl-2 mt-4">
                <b-form-group
                  label="Master filiale"
                  label-for="master-ids"
                  class="input-modal-champ mb-0  input-modal-champ-custom-master"
                >
                  <b-form-checkbox
                    v-model="checkAll"
                    @change="changeAllCheckMasters"
                    >Tous</b-form-checkbox
                  >
                </b-form-group>
                <div
                  class="chargement chargement-loading-icon mt-1 ml-3 "
                  v-if="getLoadingSettingFilialeTh"
                >
                  <div class="spinner-border sp-co-color" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <!-- <br /> -->
                <b-form-group
                  label=""
                  v-slot="{ ariaDescribedby }"
                  class="d-block"
                >
                  <div class="custom-class-fields-av scroll-specifics-params">
                    <b-form-checkbox
                      v-for="(option, index) in getSettingFilialesTh"
                      :key="index"
                      v-model="masters_id"
                      :aria-describedby="ariaDescribedby"
                      :value="option.id"
                      @change="changeOptionMaster"
                    >
                      {{ option.name }}
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </div>
            </div>
            <div v-if="error" class="error-msg mb-2">{{ error }}</div>

            <div class="actionModel">
              <b-button
                type="submit"
                class="button-valide-style mt-1"
                @click.prevent="handleGenerate"
                :disabled="projects_ids.length == 0"
              >
                <span>
                  Générer
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
              <b-button
                type="reset"
                class="button-cancel-style mt-1 ml-3"
                @click.prevent="hideModal('generateProformaMaster')"
              >
                <span>
                  Annuler
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      ref="errorModal"
      id="errorModal"
      size="lg"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal('errorModal')"
      modal-class="cutsom-modal-bootstrap  modal-dialog-rapport"
    >
      <div class="hader mb-2">
        <div class="titleSetting text-aligne-center col-10">
          Rapport Création Facture :
        </div>
        <div class="iconClose" @click.prevent="hideModal('errorModal')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <b-row v-if="listFactureSuccess.length || listFacturesExiste.length">
        <b-col
          sm="12"
          :md="listFacturesExiste && listFacturesExiste.length ? 6 : 12"
          v-if="listFactureSuccess.length"
        >
          <Card>
            <template v-slot:body>
              <b style="color: black"
                >Facture(s) ajoutée(s) avec succès ({{
                  listFactureSuccess.length
                }}):
              </b>
              <b-table-simple
                class="table-fiche custom-table-style mb-0 d-flex justify-content-center"
                responsive
              >
                <b-tbody>
                  <b-tr class="title-ligne">
                    <b-th class="newcolor">Société</b-th>
                    <b-th class="newcolor">Client</b-th>
                    <b-th class="newcolor">Numero Facure</b-th>
                    <b-th class="newcolor">Montant</b-th>
                    <b-th class="newcolor">Nombre des produits</b-th>
                    <b-th class="newcolor">Coefficient</b-th>
                    <b-th class="newcolor">Objet</b-th>
                    <b-th class="newcolor">Template</b-th>
                  </b-tr>
                  <b-tr
                    v-for="(item, index) in listFactureSuccess"
                    v-bind:key="index"
                  >
                    <b-td>{{ item.vendeur }}</b-td>
                    <b-td>{{ item.client }}</b-td>
                    <b-td>{{ item.num }}</b-td>
                    <b-td>{{ item.montant_ttc }} €</b-td>
                    <b-td>{{ item.number_of_products }} </b-td>
                    <b-td>{{ item.coef }} </b-td>
                    <b-td>{{ item.objet }} </b-td>
                    <b-td>{{ item.template }} </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>
          </Card>
        </b-col>
        <b-col
          sm="12"
          :md="listFactureSuccess && listFactureSuccess.length ? 6 : 12"
          v-if="listFacturesExiste.length"
        >
          <Card>
            <template v-slot:body>
              <b style="color: black"
                >Facture(s) modifiée(s) ({{ listFacturesExiste.length }}) :</b
              >
              <b-table-simple
                class="table-fiche custom-table-style mb-0 d-flex justify-content-center"
                responsive
              >
                <b-tbody>
                  <b-tr class="title-ligne">
                    <b-th class="newcolor">Société</b-th>
                    <b-th class="newcolor">Client</b-th>
                    <b-th class="newcolor">Numero Facure</b-th>
                    <b-th class="newcolor">Montant</b-th>
                    <b-th class="newcolor">Nombre des produits</b-th>
                    <b-th class="newcolor">Coefficient</b-th>
                    <b-th class="newcolor">Objet</b-th>
                    <b-th class="newcolor">Template</b-th>
                  </b-tr>
                  <b-tr
                    v-for="(item, index) in listFacturesExiste"
                    v-bind:key="index"
                  >
                    <b-td>{{ item.vendeur }}</b-td>
                    <b-td>{{ item.client }}</b-td>
                    <b-td>{{ item.num }}</b-td>
                    <b-td>{{ item.montant_ttc }} €</b-td>
                    <b-td>{{ item.number_of_products }} </b-td>
                    <b-td>{{ item.coef }} </b-td>
                    <b-td>{{ item.objet }} </b-td>
                    <b-td>{{ item.template }} </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>
          </Card>
        </b-col>
      </b-row>
      <b-row v-if="listProformaToFacture.length || locked_factures.length">
        <b-col
          sm="12"
          :md="locked_factures && locked_factures.length ? 6 : 12"
          v-if="listProformaToFacture.length"
        >
          <Card>
            <template v-slot:body>
              <b style="color: black"
                >Proforma transfère à facture ({{
                  listProformaToFacture.length
                }}) :</b
              >
              <b-table-simple
                class="table-fiche custom-table-style mb-0 d-flex justify-content-center"
                responsive
              >
                <b-tbody>
                  <b-tr class="title-ligne">
                    <b-th class="newcolor">Societe</b-th>
                    <b-th class="newcolor">Client</b-th>
                    <b-th class="newcolor">Numero Facure</b-th>
                    <b-th class="newcolor">Montant</b-th>
                    <b-th class="newcolor">Numéro proforma</b-th>
                  </b-tr>
                  <b-tr
                    v-for="(item, index) in listProformaToFacture"
                    v-bind:key="index"
                  >
                    <b-td>{{ item.vendeur }}</b-td>
                    <b-td>{{ item.client }}</b-td>
                    <b-td>{{ item.num }}</b-td>
                    <b-td>{{ item.montant_ttc }} €</b-td>
                    <b-td>{{ item.num_proforma }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>
          </Card>
        </b-col>
        <b-col
          v-if="locked_factures.length"
          sm="12"
          :md="listProformaToFacture && listProformaToFacture.length ? 6 : 12"
        >
          <Card>
            <template v-slot:body>
              <b style="color: black"
                >Facture(s) verrouille(s)({{ locked_factures.length }}) :</b
              >
              <b-table-simple
                class="table-fiche custom-table-style mb-0 d-flex justify-content-center"
                responsive
              >
                <b-tbody>
                  <b-tr class="title-ligne">
                    <b-th class="newcolor">Numero Facure</b-th>
                    <b-th class="newcolor">Montant</b-th>
                  </b-tr>
                  <b-tr
                    v-for="(item, index) in locked_factures"
                    v-bind:key="index"
                  >
                    <b-td>{{ item.num }}</b-td>
                    <b-td>{{ item.ttc }} €</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>
          </Card>
        </b-col>
      </b-row>
      <b-row
        class="mt-4"
        v-if="listSocieteErrorRib.length || listSocieteError.length"
      >
        <b-col
          sm="12"
          :md="listSocieteError && listSocieteError.length ? 6 : 12"
          v-if="listSocieteErrorRib.length"
        >
          <Card>
            <template v-slot:body>
              <b style="color: black"
                >Société sans RIB ({{ listSocieteErrorRib.length }}):</b
              >
              <ul style="list-style-type: none">
                <li
                  v-for="(item, index) in listSocieteErrorRib"
                  v-bind:key="index"
                  style="color: black"
                >
                  {{ item }}
                </li>
              </ul>
            </template>
          </Card>
        </b-col>
        <b-col
          sm="12"
          :md="listSocieteErrorRib && listSocieteErrorRib.length ? 6 : 12"
          v-if="listSocieteError.length"
        >
          <Card>
            <template v-slot:body>
              <b style="color: black"
                >Société sans séquance ({{ listSocieteError.length }}):</b
              >
              <ul style="list-style-type: none">
                <li
                  v-for="(item, index) in listSocieteError"
                  v-bind:key="index"
                  style="color: black"
                >
                  {{ item }}
                </li>
              </ul>
            </template>
          </Card>
        </b-col>
      </b-row>
      <b-row class="mt-4" v-if="noChange">
        <b-col sm="12" ms="12"
          ><Card>
            <template v-slot:body
              ><b style="color: black; padding-top:5px ">{{
                noChange
              }}</b></template
            ></Card
          ></b-col
        ></b-row
      >
      <b-row class="mt-4" v-if="qteNegative && qteNegative.length">
        <b-col sm="12" ms="12"
          ><Card>
            <template v-slot:body
              ><b
                style="color: black; padding-top:5px "
                v-for="(i, index) in qteNegative"
                :key="'qteNegative' + index"
                >- {{ i }}<br /></b></template></Card></b-col
      ></b-row>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'GenerateFactureMaster',
  data() {
    return {
      loading: false,
      error: null,
      locked_factures: [],
      listProformaToFacture: [],
      listFacturesExiste: [],
      listFactureSuccess: [],
      listSocieteError: [],
      listSocieteErrorRib: [],
      noChange: null,
      qteNegative: [],
      // date_debut: null,
      date_depot: null,
      masters_id: [],
      checkAll: true,
      projects_ids: [],
      checkAllDossier: false,
      loaderProject: false,
      searchValue: null
    };
  },
  props: {
    functionGenerate: { required: true }
  },
  computed: {
    ...mapGetters([
      'getSettingFilialesTh',
      'getLoadingSettingFilialeTh',
      'getListProjectForSpecificDate'
    ]),
    computedListProduct() {
      let table = this.getListProjectForSpecificDate;
      if (this.searchValue != null) {
        table = table.filter(item =>
          item.numero_dossier
            .toLowerCase()
            .includes(this.searchValue.toLowerCase())
        );
      }
      return table;
    }
  },
  methods: {
    ...mapActions(['fetchProjectForSpecifcDate', 'resetProjectForSpecifcDate']),
    async handleChangeDateDepot() {
      this.projects_id = [];
      if (this.date_depot != null) {
        this.loaderProject = true;
        await this.fetchProjectForSpecifcDate({
          date_depot: moment(this.date_depot).format('DD-MM-YYYY')
        });
        this.loaderProject = false;
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    showModal(ref) {
      this.$refs[ref].show();
    },
    resetModal(ref) {
      this.loading = false;
      this.error = null;
      if (ref == 'errorModal') {
        this.locked_factures = [];
        this.listProformaToFacture = [];
        this.listFacturesExiste = [];
        this.listFactureSuccess = [];
        this.listSocieteError = [];
        this.listSocieteErrorRib = [];
        this.noChange = null;
        this.qteNegative = [];
        this.date_depot = null;
        this.masters_id = [];
        this.checkAll = true;
        this.$emit('RefreshData');
      }
    },
    async handleGenerate() {
      this.error = null;
      if (
        // this.date_debut == null ||
        this.date_depot == null ||
        this.masters_id.length == 0
      ) {
        this.error = 'Tous les champs sont obligatoires';
      } else {
        this.loading = true;
        const response = await this.functionGenerate({
          // date_debut: this.date_debut,
          date_depot: this.date_depot,
          masters_ids: this.masters_id,
          projects_ids: this.projects_ids
        });
        if (response.success) {
          this.hideModal('generateProformaMaster');
          this.error = null;
          this.loading = false;
          this.locked_factures = response.response.locked_factures;
          this.listProformaToFacture = response.response.proforma_to_facture;
          this.listFacturesExiste = response.response.updated;
          this.listFactureSuccess = response.response.created;
          this.listSocieteError = response.response.no_seq;
          this.listSocieteErrorRib = response.response.no_rib;
          this.noChange = response.response.no_changes;
          this.qteNegative = response.response.qte_negative;
          this.showModal('errorModal');
        } else {
          this.error = response.errors;
          this.loading = false;
        }
      }
    },
    handleOpenModal() {
      this.projects_ids = [];
      this.resetProjectForSpecifcDate();
      this.changeAllCheckMasters();
    },
    changeAllCheckMasters() {
      this.masters_id = this.checkAll
        ? this.getSettingFilialesTh.map(i => i.id).slice()
        : [];
    },
    changeOptionMaster() {
      this.checkAll =
        this.masters_id.length == this.getSettingFilialesTh.length
          ? true
          : false;
    },
    //change dossier
    changeAllCheckDossiers() {
      this.projects_ids = this.checkAllDossier
        ? this.getListProjectForSpecificDate.map(i => i.id).slice()
        : [];
    },
    changeOptionDossier() {
      this.checkAllDossier =
        this.projects_ids.length == this.getListProjectForSpecificDate.length
          ? true
          : false;
    }
  },
  watch: {
    getSettingFilialesTh() {
      this.changeAllCheckMasters();
    }
  },
  components: {
    Card: () => import('@/views/component/card.vue')
  }
};
</script>

<style lang="scss">
.paragraph-style {
  color: #343a40 !important;
  font-family: sans-serif;
  font-size: 15px;
}
.scroll-specifics-params {
  height: 150px;
  overflow-y: auto;
  align-content: start;
  align-items: flex-start;
}
.scroll-specifics-params::-webkit-scrollbar-track {
  background: #f0eef8;
  border-radius: 30px;
  width: 15px;
  border-right: 10px solid white;
  border-left: 10px solid white;
  border-bottom: 10px solid white;
  border-top: 10px solid white;
}
.scroll-specifics-params::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}
.scroll-specifics-params::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 25px;
  border: 12px solid transparent;
  background-clip: padding-box;
}
.custom-class-fields-av {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  overflow-x: auto;
  text-align-last: left;
  max-height: calc(100% - 90px);
}
.custom-class-fields-av {
  .custom-control {
    width: 46%;
  }
}
.input-modal-champ-custom-master {
  .custom-control {
    text-align: start;
  }
}
.sp-co-color {
  color: #4d4bac;
}
</style>
